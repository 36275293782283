<template>
  <div>
    <div class="fill-address">
      <div class="title">Datos de recepción</div>
      <div class="fill-area">
        <div>
          <p>Nombre：</p>
          <el-input v-model="map.username" placeholder=""></el-input>
        </div>
        <div>
          <p>Numero de celular：</p>
          <el-input v-model="map.mobile" placeholder=""></el-input>
        </div>
        <div>
          <p>Dirección：</p>
          <el-input v-model="map.address" placeholder=""></el-input>
        </div>
        <div>
          <p>Establecer como dirección por defecto：</p>
          <el-switch
            v-model="map.defaultShip"
            active-color="#45B035"
            inactive-color="#EBEBEB"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </div>
      </div>
      <div class="submit">
        <el-button
          v-if="editStatus === 'add'"
          type="success"
          round
          :loading="btnLoading"
          style="width: 400px; border-radius: 28px"
          @click="sumbitData"
        >
          <span style="font-size: 18px">Aplicar</span>
        </el-button>
        <div v-if="editStatus === 'edit'">
          <el-button
            round
            style="width: 250px; border-radius: 28px"
            @click="handleCancel"
          >
            <span style="font-size: 18px">Cancelar</span>
          </el-button>
          <el-button
            type="success"
            round
            :loading="btnLoading"
            style="width: 250px; border-radius: 28px"
            @click="updateData"
          >
            <span style="font-size: 18px">Editar</span>
          </el-button>
        </div>
      </div>
    </div>

    <div class="address-list">
      <div class="title">Lista de direcciones</div>
      <div class="table-container">
        <template v-if="addressList.length">
          <el-table :data="addressList" border stripe style="width: 100%">
            <el-table-column
              align="center"
              prop="username"
              label="Nombre"
              width="180"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="mobile"
              label="Numero de celular"
              width="180"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="address"
              label="Ciudad"
            ></el-table-column>
            <el-table-column align="center" label="Operaciones">
              <template slot-scope="scope">
                <p>
                  <el-button
                    type="text"
                    style="color: #67c23a"
                    @click="editData(scope.row)"
                  >
                    Editar
                  </el-button>
                  <el-button
                    type="text"
                    style="color: #979797"
                    @click="deleteData(scope.row)"
                  >
                    Borrar
                  </el-button>
                </p>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="Dirección predeterminada"
              width="150px"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.defaultShip"
                  active-color="#45B035"
                  inactive-color="#EBEBEB"
                  :active-value="1"
                  :inactive-value="0"
                  disabled
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectIf"
              align="center"
              label="Seleccionar"
              width="150px"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.selectShip"
                  :active-value="1"
                  :inactive-value="0"
                  @change="switchChange(scope.row)"
                ></el-switch>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div v-else class="empty-container">
          <img src="@/assets/public/empty.png" alt="" />
          <span>Nada todavía~</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['selectIf'],

  data() {
    return {
      input: '',
      value: '',
      addressList: [],
      map: {
        id: '',
        username: '',
        mobile: '',
        address: '',
        defaultShip: 0
      },
      editStatus: 'add',
      btnLoading: false
    }
  },

  created() {
    this.getList()
  },

  methods: {
    getList() {
      this.$axios.post('/memberShip/list', {}).then((res) => {
        this.addressList = res.data.result.rows
        if (this.selectIf) {
          this.addressList.map((item) => {
            item.selectShip = item.defaultShip
          })
        }
        console.log('地址列表', this.addressList)
      })
    },

    sumbitData() {
      console.log(this.map)
      this.map.id = ''
      this.handlePost()
    },

    updateData() {
      this.handlePost()
    },

    editData(row) {
      for (let key in this.map) {
        this.map[key] = row[key]
      }
      this.editStatus = 'edit'
    },

    handleCancel() {
      this.editStatus = 'add'
      for (let key in this.map) {
        this.map[key] = ''
      }
    },

    handlePost() {
      console.log(this.map)
      this.btnLoading = true
      this.$axios
        .post('/memberShip/add', this.map)
        .then(() => {
          this.btnLoading = false
          this.$message.success('Exitoso')
          this.editStatus = 'add'
          for (let key in this.map) {
            this.map[key] = ''
          }
          this.getList()
        })
        .catch(() => {
          this.btnLoading = false
        })
    },

    deleteData(row) {
      this.$confirm('¿Estás seguro de que quieres borrar esta dirección?', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        confirmButtonColor: '#8ACF66'
      }).then((res) => {
        this.$axios.post('/memberShip/delete', { id: row.id }).then(() => {
          this.getList()
          this.$message.success('Exitoso')
        })
      })
    },

    switchChange(row) {
      this.$emit('selectId', row.id)

      //订单只能选择一个地址， 因此只高亮当前的选择行的按钮
      this.addressList.map((item) => {
        item.selectShip = 0
      })

      row.selectShip = 1
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
}

.fill-address {
  margin-bottom: 40px;
  .fill-area {
    margin-top: 20px;
    > div {
      display: inline-block;
      width: 460px;
      margin: 10px 0;
      p {
        padding: 10px 0;
      }
    }
    > div:nth-child(2n + 2) {
      margin-left: 60px;
    }
  }

  .submit {
    text-align: center;
    margin-top: 40px;
  }
}

.address-list {
  .table-container {
    margin: 20px 0;
  }
}
</style>
