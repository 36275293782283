<template>
  <div class="edition-center">
    <div class="edit-container">
      <div class="upload">
        <div class="uploadImg">
          <upload-image
            folder="head"
            @listenPostImage="postHead"
            type="images"
          />
        </div>
        <div class="imgVisible">
          <img v-if="info.head" :src="info.head | filterPicture" alt="" />
          <img v-else src="@/assets/public/default-head.png" alt="" />
        </div>
      </div>
      <div class="input-list">
        <div class="input-item">
          <p>Nombre de la empresa</p>
          <el-input
            v-model="query.companyName"
            disabled
            placeholder=""
          ></el-input>
        </div>
        <div class="input-item">
          <p>Dirección</p>
          <el-input v-model="query.address" disabled placeholder=""></el-input>
        </div>
        <div class="input-item">
          <p>NIT</p>
          <el-input
            v-model="query.organizationNumber"
            disabled
            placeholder=""
          ></el-input>
        </div>
        <div class="input-item">
          <p>Fecha de registro de la empresa</p>
          <el-date-picker
            v-model="query.companyCreatedTime"
            value-format="yyyy-MM-dd"
            type="date"
            disabled
            placeholder=""
          ></el-date-picker>
        </div>
        <div class="input-item">
          <p>Persona de contacto</p>
          <el-input
            v-model="query.contactPerson"
            disabled
            placeholder=""
          ></el-input>
        </div>
        <div class="input-item">
          <p>Número de contacto</p>
          <el-input v-model="query.mobile" disabled placeholder=""></el-input>
        </div>
        <div class="input-item">
          <p>Correo electrónico</p>
          <el-input v-model="query.email" disabled placeholder=""></el-input>
        </div>
      </div>

      <div class="picture-list">
        <div class="picture-item">
          <div class="title">Cédula (por ambos lados)</div>
          <div class="subtitle">Apoyo a la carga de imágenes / pdf / word</div>
          <div style="display: flex">
            <template v-if="idCardtempUrl">
              <div
                class="imgVisible"
                v-for="(item, index) in idCardtempUrl"
                :key="index"
              >
                <!-- <img @click="$store.commit('SHOW_IMAGE_WALL', item)" 
                            :src="item | filterPicture" 
                            style="cursor: pointer"> -->
                <template v-if="item.split('.')[1] === 'pdf'">
                  <a :href="item | filterPicture" target="_blank">
                    <img src="@/assets/public/pdf-icon.png" alt="" />
                  </a>
                </template>
                <template v-else-if="item.split('.')[1] === 'docx'">
                  <a :href="item | filterPicture" target="_blank">
                    <img src="@/assets/public/word-icon.png" alt="" />
                  </a>
                </template>
                <template v-else>
                  <img
                    :src="item | filterPicture"
                    style="cursor: pointer"
                    @click="$store.commit('SHOW_IMAGE_WALL', item)"
                  />
                </template>
              </div>
            </template>

            <div v-else class="imgVisible">
              <img src="@/assets/public/add.png" alt="" />
            </div>
          </div>
        </div>
        <div class="picture-item">
          <div class="title">Cámara de comercio</div>
          <div class="subtitle">Apoyo a la carga de imágenes / pdf / word</div>
          <div style="display: flex">
            <div class="imgVisible">
              <!-- <img v-if="query.businessLicenseImageUrl" 
                    @click="$store.commit('SHOW_IMAGE_WALL', query.businessLicenseImageUrl)" 
                    :src="query.businessLicenseImageUrl | filterPicture"
                    style="cursor: pointer"
                    > -->
              <template v-if="query.businessLicenseImageUrl">
                <template
                  v-if="query.businessLicenseImageUrl.split('.')[1] === 'pdf'"
                >
                  <a
                    :href="query.businessLicenseImageUrl | filterPicture"
                    target="_blank"
                  >
                    <img src="@/assets/public/pdf-icon.png" alt="" />
                  </a>
                </template>
                <template
                  v-else-if="
                    query.businessLicenseImageUrl.split('.')[1] === 'docx'
                  "
                >
                  <a
                    :href="query.businessLicenseImageUrl | filterPicture"
                    target="_blank"
                  >
                    <img src="@/assets/public/word-icon.png" alt="" />
                  </a>
                </template>
                <template v-else>
                  <img
                    :src="query.businessLicenseImageUrl | filterPicture"
                    style="cursor: pointer"
                    @click="
                      $store.commit(
                        'SHOW_IMAGE_WALL',
                        query.businessLicenseImageUrl
                      )
                    "
                  />
                </template>
              </template>
              <img v-else src="@/assets/public/add.png" />
            </div>
          </div>
        </div>
        <div class="picture-item">
          <div class="title">RUT</div>
          <div class="subtitle">Apoyo a la carga de imágenes / pdf / word</div>
          <div style="display: flex">
            <div class="imgVisible">
              <!-- <img v-if="query.taxCertificateImageUrl" 
                    @click="$store.commit('SHOW_IMAGE_WALL', query.taxCertificateImageUrl)" 
                    :src="query.taxCertificateImageUrl | filterPicture"
                    style="cursor: pointer"> -->
              <template v-if="query.taxCertificateImageUrl">
                <template
                  v-if="query.taxCertificateImageUrl.split('.')[1] === 'pdf'"
                >
                  <a
                    :href="query.taxCertificateImageUrl | filterPicture"
                    target="_blank"
                  >
                    <img src="@/assets/public/pdf-icon.png" alt="" />
                  </a>
                </template>
                <template
                  v-else-if="
                    query.taxCertificateImageUrl.split('.')[1] === 'docx'
                  "
                >
                  <a
                    :href="query.taxCertificateImageUrl | filterPicture"
                    target="_blank"
                  >
                    <img src="@/assets/public/word-icon.png" alt="" />
                  </a>
                </template>
                <template v-else>
                  <img
                    :src="query.taxCertificateImageUrl | filterPicture"
                    style="cursor: pointer"
                    @click="
                      $store.commit(
                        'SHOW_IMAGE_WALL',
                        query.taxCertificateImageUrl
                      )
                    "
                  />
                </template>
              </template>

              <img v-else src="@/assets/public/add.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadImage from '@/components/upload/image'
export default {
  props: ['info'],

  components: {
    UploadImage
  },

  data() {
    return {
      input: '',
      query: {
        loginName: '',
        loginPwd: '',
        mobile: '',
        email: '',
        companyName: '',
        address: '',
        organizationNumber: '',
        companyCreatedTime: '',
        contactPerson: '',
        idCardImageUrl: '',
        businessLicenseImageUrl: '',
        taxCertificateImageUrl: '',
        code: ''
      },
      idCardtempUrl: [],
      forbidClick: true
    }
  },

  created() {
    if (this.info) {
      for (let key in this.query) {
        this.query[key] = this.info[key]
      }

      this.idCardtempUrl = this.query.idCardImageUrl.split(',')
    }
  },

  methods: {
    postHead(url) {
      console.log(url)
      this.info.head = url

      this.$axios.post('/member/update', { head: url }).then((res) => {
        this.$axios.post('member/info', {}).then((res) => {
          this.$store.commit('SET_INFO', res.data.result)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.edition-center {
  width: 1200px;
  margin: 0 auto;

  .edit-container {
    padding: 0 90px;
    .upload {
      display: flex;
      margin-bottom: 20px;
      .imgVisible {
        overflow: hidden;
        width: 120px;
        height: 120px;
        margin-left: 20px;
        border-radius: 50%;
        img {
          display: block;
          width: 100%;
          height: 120px;

          object-fit: cover;
        }
      }
    }
    .input-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .input-item {
        width: 460px;
        margin-top: 20px;
        p {
          margin-bottom: 10px;
        }

        ::v-deep .el-input.is-disabled .el-input__inner {
          color: #282828;
        }
      }
    }

    .picture-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 50px;
      .picture-item {
        width: 460px;
        margin-bottom: 20px;
        .subtitle {
          color: #979797;
          font-size: 14px;
        }
        .upload,
        .imgVisible {
          display: inline-block;
          width: 120px;
          height: 120px;
          margin: 10px 20px 0 0;
          img {
            display: block;
            width: 100%;
            height: 120px;

            object-fit: cover;
          }
        }
      }
    }

    .submit {
      margin: 50px 0;
      text-align: center;
    }
  }

  .uploadImg {
  }
}
</style>
