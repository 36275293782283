<template>
  <div class="">
    <el-tabs
      tab-position="top"
      @tab-click="changeTabsIndex"
      style="width: 100%"
      v-model="activeTab"
      class=""
    >
      <el-tab-pane
        v-for="(item, index) in tabs"
        :key="index"
        :label="item.title"
        class=""
        :name="item.title"
      >
        <span slot="label">
          <el-badge :value="item.count" class="elBadge">
           {{ item.title }}
          </el-badge>
        </span>

        <div class="header">
          <div style="flex: 3">Productos Básicos</div>
          <div>Precio unitario</div>
          <div>Cantidad</div>
          <div>Cantidad entregada</div>
          <div>Subtotal</div>
          <div>Total</div>
          <div style="padding: 0 15px">Operaciones de productos básicos</div>
          <div>Operaciones</div>
        </div>
        <div>
          <the-order-item v-if="list.length" :orders="list" />
          <div v-else class="empty-container">
            <img src="@/assets/public/empty.png" alt="" />
            <span>Nada todavía~</span>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="query.pageNo"
            :page-size="query.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="totals"
          ></el-pagination>
        </div>
      </el-tab-pane>

      <el-tab-pane label="Reembolso postventa ">
        <div slot="label" class="relative" style="width: 180px">
          Reembolso postventa
          <span class="elBadgeSale">
            <el-badge
              :value="orderCount.afterSale ? orderCount.afterSale : 0"
            ></el-badge>
          </span>
        </div>

        <div class="header">
          <div>Productos Básicos</div>
          <div>Precio unitario</div>
          <div>Cantidad</div>
          <div>Importe de la restitución</div>
        </div>
        <div v-loading="dataLoading">
          <div class="order-list" v-if="afterSaleList.length">
            <div
              class="order-item"
              v-for="(order, orderIndex) in afterSaleList"
              :key="orderIndex"
            >
              <div class="item-header">
                <div style="display: flex; align-items: center">
                  <div>números {{ order.id }}</div>
                  <div style="color: #45b035; margin: 0 30px">
                    {{ statusMap[order.status] }}
                  </div>
                  <div>
                    <el-button
                      type="text"
                      style="color: orange; cursor: pointer"
                      @click="toAfterSaleDetail(order)"
                    >
                      Ver detalle del pedido
                    </el-button>
                  </div>
                </div>

                <div>
                  <div
                    style="
                      color: #8acf66;
                      display: inline-block;
                      margin-right: 15px;
                    "
                  >
                    {{
                      order.orderAfterStatus
                        ? 'Bajo verificación'
                        : statusMap[order.orderStatus]
                    }}
                  </div>
                  <span>{{ order.applyTime.split(' ')[0] }}</span>
                </div>
              </div>
              <div
                class="item-content"
                v-for="(child, childIndex) in order.orderGoodObjList"
                :key="childIndex"
              >
                <div class="item-goods">
                  <div class="item-goods-list">
                    <div class="item-goods-flex">
                      <div style="flex: none">
                        <div class="list-item">
                          <div class="item-img">
                            <img
                              :src="child.goodsCoverUrl | filterPicture"
                              alt=""
                            />
                          </div>
                          <div class="item-text">
                            <div class="item-name">
                              <span class="goods-sn">
                                {{ child.goodsSn }}
                              </span>
                              <span>
                                {{ child.goodsName }}
                              </span>
                            </div>
                            <div class="specs">
                              <span
                                v-for="(item, index) in JSON.parse(
                                  child.goodsSpecs
                                )"
                                :key="index"
                              >
                                {{ (index === 0 ? '' : ' / ') + item.name }} :
                                {{ item.value }}
                              </span>
                            </div>
                            <div class="barCode">
                              Código de barras：{{ child.skuBarCode }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="price">
                        COP
                        {{ child.goodsPrice | filterPrice }}
                      </div>
                      <div>{{ child.deliveryNumber }}</div>
                      <div class="price">
                        COP
                        {{ child.goodsPriceOriginal | filterPrice }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty-container">
            <img src="@/assets/public/empty.png" alt="" />
            <span>Nada todavía~</span>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            background
            @current-change="handleAfterPage"
            :current-page="afterSaleTemp.pageNo"
            :page-size="afterSaleTemp.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="afterSaleTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import cardHorizontal from '@/components/product/card-horizontal.vue'
import utils from 'my-tools-library'
import TheOrderItem from './components/The-order-item.vue'
import { scrollToTop } from 'my-utility-function'

export default {
  components: {
    cardHorizontal,
    TheOrderItem
  },
  props: ['tabIndex'],

  data() {
    return {
      currentPage4: 1,
      activeTab: 'Todos los pedidos',
      tabs: [
        {
          title: 'Todos los pedidos',
          status: '',
          count: ''
        },
        {
          title: 'Pendiente de revisión',
          status: 'INSPECTION', //待验货
          count: ''
        },
        {
          title: 'Pedido listo',
          status: 'CONFIRM', // 待确认
          count: ''
        },
        {
          title: 'Pago pendiente',
          status: 'UNPAY', //待支付
          count: ''
        },
        {
          title: 'Revisión pendiente',
          status: 'REVIEW', //待审核
          count: ''
        },
        {
          title: 'Envío pendiente',
          status: 'PAY', // 已付款

          count: ''
        },
        {
          title: 'Para ser entregado',
          status: 'TO_BE_DELIVERED', //待配送
          count: ''
        },
        {
          title: 'Pendiente por recibir',
          status: 'DELIVERY', // 已发货
          count: ''
        },

        {
          title: 'Recibió',
          status: 'RECEIVE', // 已收货
          count: ''
        },
        {
          title: 'Evaluado',
          status: 'COMMENT', // 已评价
          count: ''
        }
      ],
      tabsActiveIndex: 0,
      listTotal: 0,
      statusMap: {
        INSPECTION: 'Pendiente de revisión', //待验货
        UNPICKING: 'En alistamiento', //待拣货
        UNPAY: 'Pago pendiente', //待付款
        REVIEW: 'Revisión pendiente', //待审核
        PAY: 'Envío pendiente', //待发货
        DELIVERY: 'Pendiente por recibir', //待收货
        received: 'Recibió', //已收货
        completed: 'Terminado', //已完成
        RECEIVE: 'Pendiente por comentarios', //已收货
        COMMENT: 'Evaluado', //已完成
        CANCEL: 'Pedido cerrado',
        CLOSE: 'Pedido cerrado',
        CONFIRM: 'Confirmación pendiente', //待确认
        APPLY: 'Bajo verificación',
        REFUSE: 'Fallo de postventa',
        PASS: 'Reembolso en curso',
        COMPLETE: 'Reembolso exitoso'
      },

      afterSaleTemp: {
        pageNo: 1,
        pageSize: 10
      },

      afterSaleList: [],
      afterSaleTotal: 0,

      dataLoading: true,
      orderCount: {},
      query: {
        pageNo: 1,
        pageSize: 10,
        orderStatus: ''
      },
      list: [],
      totals: 0
    }
  },

  activated() {
    if (localStorage.getItem('mineTabIndex')) {
      console.log('index', localStorage.getItem('mineTabIndex'))
      this.activeTab = 'Recibió'
      this.tabsActiveIndex = 8
      localStorage.setItem('mineTabIndex', '')
    }

    this.getOrderCount()
    this.getOrders()
  },

  methods: {
    // 获取徽标数据
    getOrderCount() {
      this.$axios.post('/childOrder/getStatusTotalNumber ').then((res) => {
        let {
          afterSale,
          comment,
          confirm,
          delivery,
          inspection,
          pay,
          receive,
          review,
          unPay,
          unPicking,
          cancel,
          toBeDelivered,
          unPayOfSettlementType1,
          unPayOfSettlementType2,
          unPayOfSettlementType3
        } = res.data.result
        this.orderCount = res.data.result
       

        this.tabs[0].count =
          Number(cancel) +
          Number(comment) +
          Number(confirm) +
          Number(delivery) +
          Number(inspection) +
          Number(pay) +
          Number(receive) +
          Number(review) +
          Number(unPay) +
          Number(unPicking) +
          Number(toBeDelivered)
        this.tabs[1].count = inspection || 0
        this.tabs[2].count = confirm || 0
        this.tabs[3].count = this.orderCount.unPay || 0
        this.tabs[4].count = review || 0
        this.tabs[5].count = pay || 0
        this.tabs[6].count = toBeDelivered || 0
        this.tabs[7].count = delivery || 0
        this.tabs[8].count = receive || 0
        this.tabs[9].count = comment || 0
        console.log('订单红点数量', this.orderCount)
        // this.getOrders()
      })
    },
    //切换页数--商品订单
    handleCurrentChange(val) {
      this.query.pageNo = val
      this.getOrders()
    },

    //切换页数--售后订单
    handleAfterPage(val) {
      this.afterSaleTemp.pageNo = val
      this.getOrders()
    },

    //获取订单列表
    async getOrders() {
      this.$loading({ text: 'Espera un momento' })
      try {
        const {
          data: {
            result: { rows, total }
          }
        } = await this.$axios.post('/order/list', this.query)
        this.list = rows
        this.totals = +total
        console.log('订单列表 :>> ', this.list)
        this.$loading().close()
        scrollToTop()
      } catch (error) {
        console.log('error==== :>> ', error)
        this.$loading().close()
      }
    },

    reGetOrder() {
      this.tabs[this.tabsActiveIndex]['query'].pageNo = 1
      console.log('重新获取当前tab页列表')
      this.getOrders()
    },

    //切换tab栏
    changeTabsIndex({ index }) {
      console.log('event :>> ', index)
      this.list = []
      this.tabsActiveIndex = index
      if (+index !== 10) {
        this.query.orderStatus = this.tabs[index].status
        this.query.pageNo = 0
        this.getOrders()
      } else {
        this.getAfterSaleList()
      }
    },

    getAfterSaleList() {
      this.dataLoading = true
      this.$axios
        .post('/orderAftersale/list', this.afterSaleTemp)
        .then((res) => {
          console.log('售后列表', res.data.result)
          this.afterSaleList = res.data.result.rows
          this.afterSaleTotal = parseInt(res.data.result.total)
          this.dataLoading = false
          console.log('售后列表---', this.afterSaleList)
        })
        .catch(() => {
          this.dataLoading = false
        })
    },

    //去到订单详情
    toDetail(order) {
      console.log('order', order)
      this.$router.push({
        path: '/order-detail',
        name: 'order-detail',
        params: { id: order.id }
      })
    },

    //去到售后订单详情
    toAfterSaleDetail(order) {
      this.$router.push({
        path: '/afterSale-detail',
        name: 'afterSale-detail',
        params: { id: order.id }
      })
    },

    //确认收货
    receiveOrder(order) {
      this.$confirm('¿Has confirmado la recepción?', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((res) => {
        this.$axios
          .post('/order/receiveOrder', { orderId: order.id })
          .then((res) => {
            this.$message.success('Confirmar el éxito de la recepción')
            setTimeout(() => {
              this.reGetOrder()
            }, 2000)
          })
      })
    },

    //去付款
    toPay(order) {
      this.$router.push({
        path: '/pay',
        name: 'pay',
        params: {
          orderId: order.id,
          totalPrice: order.pricePay,
          billingCertificateType: order.billingCertificateType
        }
      })
    },

    //去评价
    toEvaluate(orderId, orderGoodsId) {
      // console.log(orderId)
      this.$router.push({
        path: '/evaluate',
        name: 'evaluate',
        params: { id: orderId, orderGoodsId: orderGoodsId }
      })
    },

    //删除订单
    delOrder(orderId) {
      this.$confirm('¿Está seguro de eliminar el pedido?', {
        //您确定删除订单
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((res) => {
        this.$axios.post('/order/delete', { id: orderId }).then((res) => {
          this.$message.success('El pedido fue eliminada') //订单已被删除
          setTimeout(() => {
            this.reGetOrder()
          }, 2000)
        })
      })
    },

    //取消订单
    cancelOrder(orderId) {
      //您确定取消订单
      this.$confirm('¿Deseas cancelar el pedido?', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((res) => {
        this.$axios
          .post('/order/cancelOrder', { orderId: orderId })
          .then((res) => {
            this.$message.success('El pedido fue cancelado') //订单已取消
            setTimeout(() => {
              this.reGetOrder()
            }, 2000)
          })
      })
    },

    toAfterSale(order, unitGoodsId = '') {
      console.log('002', order, unitGoodsId)
      this.$router.push({
        path: '/afterSaleOrder',
        name: 'afterSaleOrder',
        params: { id: order.id }
      })
    }
  }
}
</script>
<style>
.elBadge .el-badge__content.is-fixed {
  position: absolute !important;
  top: 0 !important;
  right: unset;
  transform: none !important;
  /* right: 0;
  top: 0; */
}

.elBadgeSale {
  position: absolute;
  top: -6px;
  right: 5px;
}
</style>
<style lang="scss" scoped>
::v-deep .el-tabs__item.is-active {
  color: #45b035;
}
::v-deep .el-tabs__active-bar {
  background-color: #45b035;
}
::v-deep .el-tabs__item:hover {
  color: #45b035;
}
::v-deep .el-tabs__item {
  padding: 0 18px;
}

$goods-item-height: 110px;

.price {
  color: #d43a57;
}

.header {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #979797;
  padding: 10px 0 10px 20px;
  background: #f9fbf9;
  text-align: center;
  > div {
    flex: 1;
  }
}

.order-list {
  .order-item {
    margin-top: 20px;
    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      background: #f7fff7;
      font-size: 14px;
      color: #606060;
      padding: 0 20px;
      border: 1px solid #e7e7e7;
    }
    .item-content {
      display: flex;
      font-size: 14px;
      text-align: center;
      border-right: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      .item-goods {
        flex: 6.5;
        .item-goods-list {
          padding: 10px 0;
          border-bottom: 1px solid #e7e7e7;
          .item-goods-flex {
            display: flex;
            align-items: center;
            height: $goods-item-height;
            > div {
              flex: 1;
            }
          }
        }

        :nth-last-child(1) {
          border-bottom: none;
        }
      }
      > div {
        flex: 1;
        border-left: 1px solid #e7e7e7;
        padding-top: 10px;
        .alignment {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          ::v-deep .el-button {
            white-space: normal;
          }
        }
        .order-status {
          height: $goods-item-height;
          padding: 10px 0;
          border-bottom: 1px solid #e7e7e7;
          display: flex;
          align-items: center;
          justify-content: center;
          ::v-deep .el-button {
            white-space: normal;
          }
          ::v-deep .el-button + .el-button {
            margin: 0;
          }
        }
        .order-status:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
  }
}

.pagination {
  margin-top: 40px;
  text-align: right;
}
</style>

<style lang="scss" scoped>
.list-item {
  display: flex;
  cursor: pointer;
  padding-left: 20px;
  text-align: initial;
  .item-img {
    width: 80px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 8px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .item-text {
    max-width: 180px;
    position: relative;
    .item-name {
      font-size: 14px;
      color: #000;
      margin: 8px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      .goods-sn {
        display: inline-block;
        background: #fcbbbb;
        color: #ffffff;
        padding: 0 5px;
        border-radius: 6px;
        margin-right: 10px;
      }
    }
    .specs,
    .barCode {
      color: #979797;
      font-size: 14px;
      margin: 2px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .border {
    border: solid 1px red;
  }
}
</style>
