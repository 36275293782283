<!--
 * @Author: 52203928+yingzilin@users.noreply.github.com
 * @LastEditTime: 2024-03-21 11:10:42
-->
<template>
  <div>
    <div v-if="orders.length" class="order-list">
      <div
        class="order-item"
        v-for="(order, orderIndex) in orders"
        :key="orderIndex"
      >
        <div class="item-header" style="border-bottom: none">
          <div>números {{ order.id }}</div>
          <div class="mlr-50px">
            Servicio al cliente: Nadie
            {{ order.customerServiceDetail.employeeName }} &emsp;
            {{ order.customerServiceDetail.employeePhone }}
          </div>
          <div>
            Línea directa al consumidor：{{
              order.salesEmployeeDetail.employeeName
            }}
            &emsp; {{ order.salesEmployeeDetail.employeePhone }}
          </div>
        </div>
        <div class="item-header" style="border-bottom: none">
          <div style="color: #d43a57">
            Precio total del pedido: COP
            {{ orderTotalPrice(order) | filterPrice }}
          </div>
        </div>
        <div v-for="(item, index) in order.childOrderList" :key="index">
          <div class="item-header justify-between">
            <div>{{ item.orderNum }}</div>
            <div>
              <div
                v-if="!item.aftersales"
                style="
                  color: #8acf66;
                  display: inline-block;
                  margin-right: 15px;
                "
              >
                {{ statusMap[item.status] }}
              </div>
              <div
                v-else
                style="
                  color: #8acf66;
                  display: inline-block;
                  margin-right: 15px;
                "
              >
                {{ item.status | filterAfterSaleStatus }}
              </div>
              <span>{{ item.createdTime.split(' ')[0] }}</span>
            </div>
          </div>
          <div class="item-content">
            <div class="item-goods">
              <div
                class="item-goods-list"
                v-for="(goods, goodIndex) in item.orderGoodsList"
                :key="goodIndex"
              >
                <div class="item-goods-flex">
                  <div style="flex: none; margin-right: 20px">
                    <div class="list-item">
                      <div class="item-img">
                        <img
                          :src="
                            filterCoverUrl(goods.goodsCoverUrl) | filterPicture
                          "
                          alt=""
                          v-if="filterCoverUrl(goods.goodsCoverUrl)"
                        />
                        <img
                          :src="goods.goodsCoverUrl | filterPicture"
                          alt=""
                          v-else
                        />
                      </div>
                      <div class="item-text">
                        <div class="item-name">
                          <span class="goods-sn">{{ goods.goodsSn }}</span>
                          <span>{{ goods.goodsName }}</span>
                        </div>
                        <div class="specs">
                          <span
                            v-for="(item, index) in goods.specs"
                            :key="index"
                          >
                            {{ (index === 0 ? '' : ' / ') + item.name }} :
                            {{ item.value }}
                          </span>
                        </div>
                        <div class="barCode">
                          Código de barras：{{ goods.skuBarCode }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="price">
                    COP {{ goods.goodsPrice | filterPrice }}
                  </div>
                  <div>{{ goods.buyNums }}</div>
                  <div>{{ goods.buyPriceDelivery }}</div>
                  <div class="price">
                    COP
                    {{
                      (goods.buyPricePay + goods.buyPriceDelivery) | filterPrice
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="price">
              <div class="alignment">
                COP {{ item.buyPricePay | filterPrice }}
              </div>
            </div>
            <div>
              <div
                class="order-status"
                v-for="(goods, goodIndex) in item.orderGoodsList"
                :key="goodIndex"
              >
                <div
                  v-if="
                    (item.status === 'RECEIVE' || item.status === 'COMMENT') &&
                    !goods.isAfterSale
                  "
                >
                  <!-- 评论 -->
                  <el-button
                    v-if="!goods.comment"
                    type="text"
                    round
                    style="margin: 3px"
                    color="#8ACF66"
                    @click="toEvaluate(item.id, goods.id)"
                  >
                    Comentario
                  </el-button>
                  <!-- 查看评论 -->
                  <el-button
                    v-if="goods.comment"
                    type="text"
                    round
                    style="margin: 3px"
                    color="#8ACF66"
                    @click="viewComments(item.id, goods.id)"
                    class="view_comment_btn relative"
                  >
                    <div
                      v-if="goods.thisOrderGoodsHasUnReadComment"
                      class="un_read"
                    ></div>
                    Ver comentarios
                  </el-button>
                  <el-button
                    type="text"
                    style="color: #979797"
                    @click="toAfterSale(item, goods.id)"
                  >
                    Solicitud de reembolso
                  </el-button>
                </div>
              </div>
            </div>
            <div>
              <div class="alignment">
                <div class="">
                  <el-button
                    type="text"
                    style="color: orange"
                    @click="toDetail(item)"
                  >
                    Ver detalle del pedido
                  </el-button>
          
                  <el-button
                    type="text"
                    v-if="
                      item.status === 'DELIVERY' &&
                      (item.settlementType == '2' ||
                        item.settlementType == '3') &&
                      order.orderConfirmation === 0
                    "
                    round
                    style="margin: 3px"
                    color="#8ACF66"
                    @click="toPay(item)"
                  >
                    Ir a pagar
                  </el-button>

                  <el-button
                    type="text"
                    v-if="item.status === 'UNPAY'"
                    round
                    style="margin: 3px"
                    color="#8ACF66"
                    @click="toPay(item)"
                  >
                    Ir a pagar
                  </el-button>
                  <!-- 评论 -->
                  <el-button
                    v-if="
                      whetherToEvaluate(order.childOrderList[index]) &&
                      order.childOrderList[index].status === 'RECEIVE'
                    "
                    type="text"
                    round
                    style="margin: 3px"
                    color="#8ACF66"
                    @click="toEvaluate(order.childOrderList[index].id)"
                  >
                    Comentario a todos
                  </el-button>
                  <el-button
                    type="text"
                    v-if="
                      (order.status === 'RECEIVE' ||
                        order.status === 'COMMENT' ||
                        order.status === 'CLOSE') &&
                      !order.orderAfterStatus
                    "
                    round
                    style="margin: 3px; color: #d43a57"
                    color="#d43a57"
                    @click="delOrder(order.id)"
                  >
                    Eliminar pedido
                  </el-button>
                  <el-button
                    type="text"
                    v-if="item.status === 'CANCEL'"
                    round
                    style="margin: 3px"
                    color="#979797"
                    @click="toAgainOrder(item)"
                  >
                    Reordenar
                  </el-button>
                  <!-- <el-button
                    type="text"
                    style="color: #979797"
                    v-if="item.status === 'RECEIVE' && !item.orderAfterStatus"
                    @click="toAfterSale(order)"
                  >
                    Solicitud de reembolso
                  </el-button> -->
                  <el-button
                    type="text"
                    style="color: #8acf66"
                    @click="addAnOrder(item)"
                  >
                    <!-- 加单 -->
                    Añadir pedido
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isJson, scrollToTop } from 'my-utility-function'
export default {
  props: {
    orders: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      statusMap: {
        INSPECTION: 'Pendiente de revisión', //待验货
        UNPAY: 'Pago pendiente', //待付款
        REVIEW: 'Revisión pendiente', //待审核
        PAY: 'Envío pendiente', //待发货
        DELIVERY: 'Pendiente por recibir', //待收货
        received: 'Recibió', //已收货
        completed: 'Terminado', //已完成
        RECEIVE: 'Pendiente por comentarios', //已收货
        COMMENT: 'Evaluado', //已完成
        CANCEL: 'Pedido cerrado',
        CLOSE: 'Pedido cerrado',
        CONFIRM: 'Confirmación pendiente', //待确认
        APPLY: 'Bajo verificación',
        REFUSE: 'Fallo de postventa',
        PASS: 'Reembolso en curso',
        COMPLETE: 'Reembolso exitoso'
      }
    }
  },
  methods: {
    /**
     * @description: 订单详情
     * @return {*}
     */
    toDetail(order) {
      console.log('order', order)
      this.$router.push({
        path: '/order-detail',
        name: 'order-detail',
        params: { id: order.id }
      })
    },

    /**
     * @description: 重新下单
     * @return {*}
     */
    toAgainOrder(val) {
      let selectedSkus = []
      val.orderGoodsList.map((item) => {
        selectedSkus.push({ goodsSkuId: item.goodsSkuId, num: item.buyNums })
      })
      console.log('创建订单的sku列表', selectedSkus)
      setTimeout(() => {
        this.$loading().close()
        this.$router.push({
          path: '/create-order',
          name: 'create-order',
          params: { id: 1, skus: selectedSkus }
        })
      }, 300)
    },
    //去付款
    toPay(order) {
      this.$router.push({
        path: '/pay',
        name: 'pay',
        params: {
          orderId: order.id,
          totalPrice: order.pricePay,
          billingCertificateType: order.billingCertificateType
        }
      })
    },
    //去评价
    toEvaluate(orderId, orderGoodsId) {
      this.$router.push({
        path: '/evaluate',
        name: 'evaluate',
        params: { id: orderId, orderGoodsId: orderGoodsId }
      })
    },
    viewComments(orderId, goodsId) {
      this.$router.push({
        path: '/evaluate-detail',
        query: { id: orderId, orderGoodsId: goodsId }
      })
    },
    toAfterSale(order, orderGoodsId) {
      console.log('002', order, orderGoodsId)
      this.$router.push({
        path: '/afterSaleOrder',
        name: 'afterSaleOrder',
        params: { id: order.id, orderGoodsId: orderGoodsId }
      })
    },
    filterCoverUrl(coverUrl) {
      const array = isJson(coverUrl)
      return array[0].url
    },
    /**
     * @description: 加单
     * @param {*} val
     * @return {*}
     */
    addAnOrder(val) {
      this.$store.commit('SET_CHILDREN_ID', val.id)
      this.$router.push({ path: '/carts' })
    },
    orderTotalPrice(arr) {
      const total = arr.childOrderList.reduce(
        (total, item) => total + item.buyPricePay,
        0
      )
      return total
    },
    whetherToEvaluate(childOrderList) {
      const newArray = childOrderList.orderGoodsList.filter(
        (item) => !item.comment
      )
      if (newArray.length > 0) return true
      else return false
    }
  }
}
</script>

<style lang="scss" scoped>
$goods-item-height: 110px;

.view_comment_btn {
  .un_read {
    width: 6px;
    height: 6px;
    background: #d43a57;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 3px;
  }
}
.order-list {
  .order-item {
    margin-top: 20px;
    .item-header {
      display: flex;
      align-items: center;
      height: 40px;
      background: #f7fff7;
      font-size: 14px;
      color: #606060;
      padding: 0 20px;
      border: 1px solid #e7e7e7;
    }
    .item-content {
      display: flex;
      font-size: 14px;
      text-align: center;
      border-right: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      .item-goods {
        flex: 6.5;
        .item-goods-list {
          padding: 10px 0;
          border-bottom: 1px solid #e7e7e7;
          .item-goods-flex {
            display: flex;
            align-items: center;
            height: $goods-item-height;
            > div {
              flex: 1;
            }
          }
        }

        :nth-last-child(1) {
          border-bottom: none;
        }
      }
      > div {
        flex: 1;
        border-left: 1px solid #e7e7e7;
        padding-top: 10px;
        .alignment {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          ::v-deep .el-button {
            white-space: normal;
          }
        }
        .order-status {
          height: $goods-item-height;
          padding: 10px 0;
          border-bottom: 1px solid #e7e7e7;
          display: flex;
          align-items: center;
          justify-content: center;
          ::v-deep .el-button {
            white-space: normal;
          }
          ::v-deep .el-button + .el-button {
            margin: 0;
          }
        }
        .order-status:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
  }
}

.list-item {
  display: flex;
  cursor: pointer;
  padding-left: 20px;
  text-align: initial;
  .item-img {
    width: 80px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 8px;
    display: flex;
    flex-shrink: 0;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .item-text {
    max-width: 180px;
    position: relative;
    .item-name {
      font-size: 14px;
      color: #000;
      margin: 8px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      .goods-sn {
        display: inline-block;
        background: #fcbbbb;
        color: #ffffff;
        padding: 0 5px;
        border-radius: 6px;
        margin-right: 10px;
      }
    }
    .specs,
    .barCode {
      color: #979797;
      font-size: 14px;
      margin: 2px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .border {
    border: solid 1px red;
  }
}
.price {
  color: #d43a57;
}
</style>
