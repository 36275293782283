<template>
  <div class="full-page">
    <div class="title">Acerca de nosotros</div>
    <div v-if="content" class="text" v-html="content"></div>
    <div v-else class="empty-container">
      <img src="@/assets/public/empty.png" alt="" />
      <span>Nada todavía~</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: ''
    }
  },
  created() {
    this.getContent()
  },
  methods: {
    getContent() {
      this.$store.dispatch('contentManagement', '关于我们').then((res) => {
        this.content = res
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.full-page {
  .title {
    padding: 20px 0;
    font-size: 20px;
    font-weight: bold;
  }
  .text {
    color: #000;
    font-size: 18px;
    line-height: 27px;
    ::v-deep p {
      // 文字两边对齐
      text-align: justify;
      word-wrap: break-word;
      img {
        display: block;
        max-width: 100%;
        margin: 20px auto;

        object-fit: cover;
      }
    }
  }
}
</style>
