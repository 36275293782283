<template>
  <div class="full-page">
    <div class="join-status" v-if="applyList.length">
      <img src="@/assets/mine/text-icon.png" alt="" />
      <span v-if="examineStatus === 'PENDING'">Auditoría en curso</span>
      <span v-if="examineStatus === 'DENY'">
        La cuenta no ha sido verificada , {{ this.remark }}
      </span>
      <span v-if="examineStatus === 'PASS'">
        La verificación ha sido aprobada, felicitaciones por convertiste en
        miembro de Calabaza Pets
      </span>
    </div>
    <div class="title">Aplicar para unirse a Calabaza Pets</div>
    <div v-if="content" class="text" v-html="content"></div>
    <div v-else class="empty-container">
      <img src="@/assets/public/empty.png" alt="" />
      <span>Nada todavía~</span>
    </div>
    <div class="" v-if="examineStatus === 'DENY'">
      <div class="check">
        <img
          v-if="!checkIf"
          src="@/assets/carts/check.png"
          @click="checkIf = !checkIf"
        />
        <img
          v-else
          src="@/assets/carts/checkActive.png"
          @click="checkIf = !checkIf"
        />
        <span>He leído y acepto el "Acuerdo de afiliación"</span>
      </div>
      <div class="submit">
        <el-button
          type="success"
          round
          :disabled="!checkIf"
          style="width: 400px; height: 56px; border-radius: 28px"
          @click="applyToJoin"
        >
          <span style="font-size: 18px">Aplicar</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: '',
      checkIf: false,
      examineStatus: 'DENY',
      applyList: [],
      remark: ''
    }
  },

  created() {
    this.getExaminStatus()
    this.getContent()
  },

  methods: {
    getContent() {
      this.$store.dispatch('contentManagement', '加盟协议').then((res) => {
        this.content = res
        console.log(res)
      })
    },

    getExaminStatus() {
      this.$axios.post('/applicationToJoin/list', {}).then((res) => {
        this.applyList = res.data.result.rows

        console.log(this.applyList)
        if (!this.applyList.length) return

        this.examineStatus = res.data.result.rows[0].status
        this.remark = res.data.result.rows[0].remark
      })
    },

    applyToJoin() {
      this.$axios.post('/applicationToJoin/add', {}).then((res) => {
        this.$message('Solicitud de afiliación presentada con éxito')
        setTimeout(() => {
          location.reload()
        }, 2000)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.join-status {
  padding: 15px 20px;
  background: #f7fff7;
  border: 1px solid #8acf66;
  img {
    width: 21px;
    vertical-align: middle;
    margin-right: 20px;
  }
  span {
    vertical-align: middle;
    color: #45b035;
  }
}

.title {
  padding: 20px 0;
  font-size: 20px;
  font-weight: bold;
}

.text {
  font-size: 18px;
  line-height: 27px;
}

.check {
  margin-top: 40px;
  img {
    width: 18px;
    vertical-align: middle;
    margin-right: 20px;
  }
  span {
    vertical-align: middle;
    color: #979797;
  }
}

.submit {
  text-align: center;
  margin-top: 100px;
}
</style>
