<template>
  <nav-bar @showInfo="showInfo">
    <div class="edition-center">
      <div class="page-container">
        <div class="tabs-control">
          <div
            :class="current == 'order' ? 'current-page' : ''"
            @click="current = 'order'"
          >
            <div>Mi pedido</div>
          </div>
          <div
            :class="current == 'join' ? 'current-page' : ''"
            @click="current = 'join'"
          >
            <div>Aplicar para unirse a Calabaza Pets</div>
          </div>
          <div
            :class="current == 'address' ? 'current-page' : ''"
            @click="current = 'address'"
          >
            <div>Configurar la dirección</div>
          </div>
          <div
            :class="current == 'collect' ? 'current-page' : ''"
            @click="current = 'collect'"
          >
            <div>Favoritos</div>
          </div>
          <!-- <div style="margin-bottom: 0" :class="current == 'about'? 'current-page' : ''" @click="current = 'about' "><div>Acerca de nosotros</div></div> -->
        </div>
        <div class="tabs-content">
          <order-index v-if="current == 'order'"></order-index>
          <join-index v-if="current == 'join'"></join-index>
          <address-index v-if="current == 'address'"></address-index>
          <collect-index v-if="current == 'collect'"></collect-index>
          <!-- <aboutUs-index v-if="current == 'about'"></aboutUs-index> -->
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      title="Datos de tu empresa"
      width="1200px"
    >
      <info-index :info="info"></info-index>
    </el-dialog>
  </nav-bar>
</template>

<script>
import navBar from '@/components/navBar/index.vue'
import cardHorizontal from '@/components/product/card-horizontal.vue'

import orderIndex from './order/index'
import joinIndex from './join/index'
import addressIndex from './address/index'
import collectIndex from './collect/index'
import aboutUsIndex from './aboutUs/index'

import infoIndex from './personInfo/index'

export default {
  components: {
    navBar,
    cardHorizontal,
    orderIndex,
    joinIndex,
    addressIndex,
    collectIndex,
    aboutUsIndex,
    infoIndex
  },

  data() {
    return {
      current: 'order',
      dialogVisible: false,
      info: {}
    }
  },

  methods: {
    showInfo() {
      this.getDetail()
    },
    getDetail() {
      this.$axios.post('/member/info', {}).then((res) => {
        this.info = res.data.result
        this.dialogVisible = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.edition-center {
  width: 1200px;
  margin: 0 auto;
}

.page-container {
  margin: 40px 0;
  display: flex;
  .tabs-control {
    width: 170px;
    margin-right: 20px;
    > div {
      width: 100%;
      height: 60px;
      background: #f9fbf9;
      border: 1px solid #e7e7e7;
      margin-bottom: 10px;
      padding: 10px;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      &.current-page {
        background: #45b035;
        color: #ffffff;
        border: none;
      }
      div {
        width: 150px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .tabs-content {
    flex: 1;
    overflow-x: auto;
  }
}
</style>
