<template>
  <div>
    <div v-if="collectList.length" class="collect-list">
      <product-card
        v-for="(item, index) in collectList"
        :key="index"
        :goods="item.objectDetail"
        @handel-collect="handelCollect(item)"
        @toDetail="toDetail(item.objectDetail.id)"
        :collect="true"
      ></product-card>
    </div>
    <div v-else class="empty-container">
      <img src="@/assets/public/empty.png" alt="" />
      <span>Nada todavía~</span>
    </div>
  </div>
</template>

<script>
import productCard from '@/components/product/index.vue'
export default {
  components: {
    productCard
  },

  data() {
    return {
      collectList: []
    }
  },
  created() {
    this.getList()
  },

  methods: {
    toDetail(goodsId) {
      this.$router.push({
        path: '/goods',
        name: 'goods',
        params: { id: goodsId }
      })
    },

    getList() {
      this.$axios.post('/operation/collectList', {}).then((res) => {
        this.collectList = res.data.result.rows
        console.log(this.collectList)
      })
    },

    handelCollect(item) {
      console.log(item)
      let collectTemp = {
        objectType: 'GOODS',
        type: 'COLLECT',
        objectId: item.objectDetail.id,
        whether: false
      }
      this.$axios.post('/operation/operation', collectTemp).then((res) => {
        this.$message.success('Exitoso')
        this.getList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.collect-list {
  > div {
    margin-top: 20px;
    margin-right: 20px;
  }
  :nth-child(3n + 3) {
    margin-right: 0;
  }
}
</style>
